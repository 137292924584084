<template>
  <div class="exception-add" v-if="!isProcessing">
    <p class="exception-add__text">
      追加したい例外ユーザーにLDWに有料課金の登録を行わずにアカウント作成ができる招待URLをお送りします。送り先のメールアドレスを入力してください。
      <br>なお、ここで登録するメールアドレスを用いてユーザーはGoogle認証を行う必要があります。
    </p>
    <user-add class="exception-add__form" :tags="tags" @set-postparam="setPostParam" />
    <v-btn class="exception-add__btn" :disabled="!canSend" depressed @click="send()">
      招待URLの送信
    </v-btn>
  </div>
</template>

<script>
import UserAdd from '@/components/exception/form/user/add.vue'

export default {
  name: 'exception_add',
  components: { UserAdd },
  data () {
    return {
      postParams: {
        // メールアドレス
        email: '',
        // 選択中のタグのID
        taid: ''
      }
    }
  },
  async mounted () {
    const promises = []

    if (this.tags.length === 0) promises.push(this.$store.dispatch('tags/getTags'))
    if (this.exemptions.length === 0) promises.push(this.$store.dispatch('exemptions/getExemptions'))

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object[]} タグ一覧
     */
    tags () {
      return this.$store.getters['tags/tags']
    },
    /**
     * @return {Object[]} 例外情報一覧
     */
    exemptions () {
      return this.$store.getters['exemptions/exemptions']
    },
    /**
     * @return {Boolean} メールアドレスが正しいフォーマットかどうか
     */
    isCorrectFormatted () {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return this.postParams.email && pattern.test(this.postParams.email)
    },
    /**
     * @return {Boolean} メールアドレス入力とタグ選択がされているかどうか
     */
    isFilled () {
      // 入力必須項目
      const requiredKey = ['email', 'taid']
      return requiredKey.every(key => this.postParams[key].length > 0)
    },
    /**
     * @return {Boolean} 送信可能かどうか
     */
    canSend () {
      return this.isCorrectFormatted && this.isFilled
    }
  },
  methods: {
    /**
     * ポストパラメータに値の代入
     * @param {String} key 格納する変数名
     * @param {String} value 格納する変数値
     */
    setPostParam (key, value) {
      this.postParams[key] = value
    },
    /**
     * 招待メール送信処理
     */
    async send () {
      this.$store.commit('setSubmitting', true)

      // 既に招待済みであればテロップを表示して処理を中断する
      const hasInvited = this.exemptions.find(exemption => exemption.email === this.postParams.email)
      if (hasInvited) {
        this.$store.commit('setSubmitting', false)
        this.$store.commit('setTelop', { show: true, msg: 'このメールアドレスは招待済みです。', type: 'warning' })
        return
      }

      const params = {
        uid: null,
        email: this.postParams.email,
        taid: this.postParams.taid,
        registratedAt: null,
        createdAt: new Date(),
        updatedAt: new Date()
      }

      await this.$store.dispatch('exemptions/addExemption', params)

      // 招待メール送信処理
      // 環境ごとにURLを作成
      const mainURL = process.env.NODE_ENV === 'production' ? 'https://ldw.jp/' :
        process.env.NODE_ENV === 'staging' ? 'https://wonderland-stg.web.app/' :
          'https://wonderland-dev.web.app/'

      const payload = {
        subject: '【LDW】 アカウント登録のお知らせ',
        content: 'この度はリンクデザインワンダーランド（LDW）にご招待したくメールさせていただきました。<br><br>' +
                 '以下のURLよりアカウント登録をお願いいたします。<br><br>' +
                 mainURL + 'signup?email=' + this.postParams.email + '<br><br>' +
                 'なお、本招待制度は一部の方に限定した制度になります。そのため第三者への情報共有は避けていただけますと幸いです。よろしくお願いします。',
        email: this.postParams.email
      }

      await this.$store.dispatch('functions/sendEmail', payload)

      this.$store.commit('setTelop', { show: true, msg: '招待メールを送信しました。', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.exception-add {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height $padding_width;
  margin: $header_height auto 0 auto;
  &__text {
    font-size: 1.2rem;
  }
  &__form {
    margin-top: 30px;
  }
  &__btn {
    display: block;
    margin: 60px auto 0;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 10px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: 126px;
      height: 36px;
      padding: 10px 15px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $white_color !important;
      background-color: $orange_lighten_color !important;
    }
  }
}
</style>
