<template>
  <div class="user-add">
    <p class="user-add__title">メールアドレス</p>
    <input class="user-add__input" placeholder="sample@sample.com"
           v-model="email" @input="emitEmail" />
    <p class="user-add__title">例外ユーザーのタグ</p>
    <div class="user-add__tags">
      <v-btn :class="active === tag.taid ? 'user-add__tags__item--active' : 'user-add__tags__item'"
             depressed v-for="tag in tags" :key="tag.name" @click="select(tag.taid)">
        <span class="user-add__tags__item__text">{{ tag.name }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // タグ一覧
    tags: {
      type: Array,
      requred: true
    }
  },
  data () {
    return {
      // メールアドレス
      email: '',
      // 活性中のタグ
      active: ''
    }
  },
  methods: {
    /**
     * 親コンポーネントへメールアドレスを渡す
     */
    emitEmail () {
      this.$emit('set-postparam', 'email', this.email)
    },
    /**
     * タグの選択
     * @param {String} taid タグのID
     */
    select (taid) {
      this.active = this.active === taid ? null : taid
      this.$emit('set-postparam', 'taid', this.active)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.user-add {
  width: 100%;
  &__title {
    margin: 0;
    font-size: 1.2rem;
    color: $black_lighten_color;
  }
  &__input {
    display: inline-block;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 40px;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $black_lighten_color;
    vertical-align: middle;
    border-bottom: 1px solid $black_color;
    &::placeholder {
      color: $gray_color;
    }
    &:focus {
      outline: none;
    }
  }
  &__tags {
    display: flex;
    flex-wrap: wrap;
    &__item {
      margin-top: 10px;
      margin-right: 10px;
      text-transform: none;
      border: 2px solid $orange_color;
      border-radius: 5px;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: 60px;
        max-width: 100%;
        height: 34px;
        padding: 0 15px;
        font-size: 1.2rem;
        color: $orange_color;
        background-color: $white_color;
      }
      &--active {
        @extend .user-add__tags__item;
        &.v-btn:not(.v-btn--round).v-size--default {
          color: $white_color;
          background-color: $orange_color;
        }
      }
      & ::v-deep .v-btn__content {
        flex: 1;
        min-width: 0;
      }
      &__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
